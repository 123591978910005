@import 'mixin';
@import 'reset';

// 
@import 'kube/kube.scss';
@import "/Users/uneazusa/sites/gcal-freetime/mockup_kube/_src/sass/ore_mock_css/_oremock.scss";


// fonts

// $font-default: -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;

// color
$c_green: #43b03e;
$c_dark_green: #2a853a;
// $c_light_green: rgba($c_green, .6);
$c_light_green: #a2d088;
$c_twitter: #5eaadf;
$c_facebook: #395898;
$c_gray: #cdcdcd;
$c_black: #000;

// body {
//   font-family: $font-default;
// }

.l-header,
.l-footer,
.l-wrap {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
}



.js-dropNext {
  text-decoration: none;
  font-size: 14px;
  &:after {
    font-family: Kube!important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;

    content: "\e901";
    display: inline-block;
    position: relative;
  }
  &.is-show {
    &:after {
      content: "\e904";
    }
  }
}
.js-dropNext_body {
  display: none;
  font-size: 12px;
  padding: 10px 0;
  line-height: 1.4;
}


section {
  margin-bottom: 40px;
}


.main-textarea {
  font-size: 16px;
  line-height: 1.4;
}


b, .b {
  font-weight: bold !important;
}
label span {
  display: inline-block;
  line-height: 1.4 !important;
}

.button {
  border-radius: 3px !important;
  &.outline {
    border-style: solid !important;
  }
}

input, select {
  font-size: 16px;
}

.sns {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 10px 0 0 0;
  li {
    margin: 0 10px 10px 10px;
  }
}


.wrap-mypage.is-PC {
  @include clearfix;
  display: flex;
  .mypage-main {
    width: 50%;
    padding: 10px 0;
    box-sizing: border-box;
    textarea {
      width: 80%;
    }
  }
  .mypage-side {
    width: 50%;
    padding: 15px;
    box-sizing: border-box;
    background: #dfdfdf;
  }
}


@import "/Users/uneazusa/sites/gcal-freetime/mockup_kube/_src/sass/modules/_footer.scss";
@import "/Users/uneazusa/sites/gcal-freetime/mockup_kube/_src/sass/modules/_header.scss";
@import "/Users/uneazusa/sites/gcal-freetime/mockup_kube/_src/sass/modules/_modal.scss";

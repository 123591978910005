.l-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.7);
}
.modal-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate3d(-50%,-50%,0);
  padding: 20px;
  background: #fff;
  border-radius: 3px;
  font-size: 12px;
  .loading {
    position: relative;
    top: 2px;
    width: 12px;
  }
}
.l-header {
  position: relative;
  margin: 0 auto;
  padding: 0px 15px 20px 15px;
  h1 {
    padding: 100px 0 0 0;
  }
  .header-login {
    position: absolute;
    right: 15px;
    top: 20px;
  }
  &.isnot-user {
    h1 {
      padding-top: 60px;
    }
  }
}
